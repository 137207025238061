@import url('./fonts.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --grey-01: #25313C;
    --grey-02: #6D7D8B;
    --grey-03: #4D5964;
    --orange: #FCAF17;
    --percentage: attr(data-parcentage)
}

body {
    font-size: 15px;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: normal;
}

button:hover,
button:focus,
input:focus {
    outline: 0;
    box-shadow: none;
}

button, a, ul li, input, span, div {
    font-family: 'Helvetica Neue', sans-serif;
}

.mw-74 {
    max-width: 73.5%;
}

.mw-75 {
    max-width: 75%;
}

.main-conatiner {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.container-wrap {
    width: 100%;
    padding-left: 236px;

}

.main-section {

    width: 100%;
    position: relative;
}

.ms-24 {
    margin-top: 24px;
}

.me-24 {
    margin-bottom: 24px;
}

.react-dropdown-select-dropdown-handle.css-1yc4zyy-DropdownHandleComponent {
    margin: 0 0 -8px 5px;
    transition: 0.3s linear;
}

.react-dropdown-select-dropdown-handle.css-1aarvou-DropdownHandleComponent {
    margin: -7px 0 0px 5px;
    transition: 0.3s linear;
}

.custom-selection {
    border: 1px solid #C2CFE0 !important;
    box-sizing: border-box;
    border-radius: 6px !important;
    padding: 7.5px 12px !important;
}

.custom-selection .react-dropdown-select-content span,
.custom-selection .react-dropdown-select-content input {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #016288;
}

.btn-primary {
    padding: 6px 12px;
    background-color: #016288;
    border: 0;
    border-radius: 6px;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
}

.btn-primary:hover {
    background-color: #016288;
}

.btn-outline-primary {
    padding: 6px 12px;
    background-color: transparent;
    border: 1px solid #016288;
    border-radius: 6px;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #016288;
}

.btn-outline-primary:hover {
    background: #016288;
    color: #FFF;
    border-color: #016288;
}

.btn-pin {
    font-size: 12px;
}

.btn-pin .icon-pin {
    font-size: 17.4px;
    margin-right: 5px;
}

.btn-secondary {
    padding: 6px 12px;
    background-color: #FCAF17;
    border: 0;
    border-radius: 6px;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
}

.btn-secondary:hover {
    background-color: #FCAF17;
}

.btn-outline-primary:focus,
.btn-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:active:focus .btn-primary:active,
.btn-primary:active:focus {
    outline: 0 !important;
    box-shadow: unset !important;
    background: #016288 !important;
    border-color: #016288 !important;
    color: #FFF !important;
}

.btn-outline-secondary {
    padding: 6px 12px;
    background-color: transparent;
    border: 1px solid #FCAF17;
    border-radius: 6px;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #FCAF17;
}

.btn-outline-secondary:hover {
    background: #FCAF17;
    color: #FFF;
    border-color: #FCAF17;
}

.btn-outline-secondary:focus,
.btn-secondary:focus,
.btn-outline-secondary:active,
.btn-secondary:active,
.btn-outline-secondary:active:focus {
    outline: 0 !important;
    box-shadow: unset !important;
    background: #FCAF17 !important;
    border-color: #FCAF17 !important;
    color: #FFF !important;
}

.thumb-scroll-track-vertical {
    position: absolute;
    width: 6px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    border-radius: 6px;
}

.thumb-scroll {
    background: #C2CFE0;
    /* width: 5px !important; */
    border-radius: 6px !important;
}

@import url('./signin.css');
@import url('./sidebar.css');
@import url('./home.css');
@import url('./trash.css');
@import url('./search-result.css');
@import url('./notification.css');
@import url('./file-detail.css');
@import url('./reminder.css');
@import url('./chat.css');
@import url('./workflow.css');
@import url('./project-details.css');
@import url('./settings.css');
@import url('./profile.css');

.consent-container {
    align-items: center !important;
}

.consent-content {
    width: 70%;
    flex: unset !important;
    padding: 25px 15px 25px 35px;
    margin: 0 !important;
}

.consent-content p {
    font-size: 14px;
    margin-bottom: 0;
}

.consent-content h2 {
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: 700;
}

.consent-buttons {
    width: 30%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* padding: 25px 0; */
}

.consent-buttons button {
    display: block;
}

.btn-cookie {
    color: #000;
    background-color: #fff;
    font-weight: 700;
    border: 0;
    border-radius: 25px;
    padding: 0.5rem 3rem;
    margin-bottom: 15px;
}

.btn-outline-cookie {
    color: #fff;
    background-color: transparent;
    font-weight: 700;
    border: 0;
    border-radius: 25px;
    padding: 0.5rem 3rem;
    margin-bottom: 0px;
}

.files-wraps {
    padding: 12px;
    background: #FFF;
    border: 1px solid #F5F6F8;
    box-shadow: 2px 13px 28px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin: 18px 0 5px;
}

.files-wraps.trash-file {
    cursor: pointer;
    position: relative;
}

.files-wraps.trash-file::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: 1px solid #016288;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #FFF;
    font-size: 12px;
}

.files-wraps.trash-file.selected-file::after {
    content: '\2713';
    background: #016288;
    color: #FFF;
    font-size: 12px;
}

.files-wraps.project-wrasp {
    cursor: pointer;
}

.files-wraps.file-selected {
    border-color: #016288;
    /* background: #f3f4f7; */
    box-shadow: 2px 13px 30px rgba(1, 98, 136, 0.2);
}

.files-wraps.project-wrasp:hover {
    border-color: #016288;
    box-shadow: 2px 13px 30px rgba(1, 98, 136, 0.2);
}

.files-wraps .top-files {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.files-wraps .top-files .files-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
}

.files-wraps .top-files .files-text p {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 0;
    word-break: break-all;
}

.files-wraps .top-files .files-text svg,
.files-wraps .top-files .files-text .icon {
    margin-right: 8px;
}

.files-wraps .top-files .files-text .icon img {
    width: 30px;
}

.files-wraps .top-files .files-text .icon .user-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #016288;
    background: #C2CFE0;
    border-radius: 50%;
}

.files-wraps .top-files .groups-btns {
    width: 95px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.files-wraps .top-files .groups-btns .btn-checkout-check {
    background: transparent;
    border: 0;
    padding: 0;
    line-height: 0;
    color: #016288;
    font-size: 22px;
    margin-right: 5px;
}

.files-wraps .top-files .groups-btns .btn-likes {
    background: transparent;
    border: 0;
    padding: 0;
}

.files-wraps .top-files .groups-btns .btn-likes svg path {
    fill: transparent;
    stroke: #C2CFE0;
    stroke-width: 2px;
}

.files-wraps .top-files .groups-btns .btn-likes.active svg path {
    fill: #FCAF17;
    stroke-width: 0;
    stroke: transparent;
}

.files-dropdown button {
    background: transparent;
    border: 0;
    color: #9EA8B6;
    font-size: 25px;
    padding: 0;
}

.files-dropdown button.btn-primary:focus,
.files-dropdown button.btn-primary:hover,
.files-dropdown button.btn-primary:active,
.files-dropdown button.btn-primary.dropdown-toggle {
    background: transparent !important;
    color: #9EA8B6 !important;
}

.files-dropdown button::after {
    display: none;
    padding: 0;
}

.files-dropdown.chat-dropdown button {
    background: #FFFFFF;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(42, 139, 242, 0.03), 0px 5px 25px rgba(42, 139, 242, 0.07);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #707C97;
}

.files-dropdown.chat-dropdown button:focus,
.files-dropdown.chat-dropdown button:hover {
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(42, 139, 242, 0.03), 0px 5px 25px rgba(42, 139, 242, 0.07) !important;
}

.files-dropdown.folder-dropdown {
    position: absolute;
    top: -5px;
    right: -12px;
    z-index: 2;
}

.bottom-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #F5F6F8;
    padding: 12px;
    border-radius: 6px;
    margin-top: 33px;
}

.user-ul {
    list-style: none;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0px;
}

.user-ul li {
    list-style: none;
    margin-left: -6px;
}

.user-ul li:first-child {
    margin-left: 0;
}

.user-ul li .user-picture-wrap {
    width: 25px;
    height: 25px;
    /* display: inline-block; */
    border: 1px solid #F5F6F8;
    border-radius: 25px;
    object-fit: fill;
    overflow: hidden;
}

/* .user-ul li:first-child {
    left: 0;
} */

.user-ul li .user-picture-wrap img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.user-ul li .user-picture-wrap .user-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #FFF;
    font-size: 10px;
    color: #016288;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.users-wrap p {
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.dates-wrap p {
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
}

.dates-wrap p svg {
    margin-right: 6px;
}

.dates-wrap p .deadline-icon {
    font-size: 18px;
    margin-right: 6px;
    line-height: 0;
    color: #C2CFE0;
}

.dates-wrap p .deadline-icon svg {
    margin-right: 0;
}

.dates-wrap span {
    display: block;
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: right;
    color: #334D6E;
}

.project-grids {
    display: flex;
    flex-direction: column;
}

.project-grids .dates-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.project-grids .dates-wrap p {
    margin-bottom: 0;
}
.file-title.folders__title{
    position: relative;
}
.count-totals{
    background: #FCAF17;
    font-size: 14px;
    line-height: 14px;
    padding: 5px;
    border-radius: 3px;
    color: #016288;
    position: absolute;
    top: -10px;
    right: -23px;
}

/* folder component */
.folders__items {
    display: flex;
    flex-wrap: wrap;
    column-count: 3;
    column-gap: 20px;
}

.folders__item {
    padding: 12px 12px 16px;
    background: #FFF;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 100%;
    max-width: 212px;
    margin: 18px 0 5px;
    border: 1px solid transparent
}

.folders__item.trash-folder-item {
    position: relative;
    cursor: pointer;
}

.folders__item.trash-folder-item::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: 1px solid #016288;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #FFF;
    font-size: 12px;
}

.folders__item.trash-folder-item.selected-folder::after {
    content: '\2713';
    background: #016288;
    color: #FFF;
    font-size: 12px;
}

.folders__item.folder-selected {
    border-color: #016288;
    background: #F5F6F8;
}

.folders__item .btn-likes.folder-favrites {
    background: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    top: -5px;
    right: 15px;
}

.folders__item .btn-likes.folder-favrites svg path {
    fill: transparent;
    stroke: #C2CFE0;
    stroke-width: 2px;
}

.folders__item .btn-likes.folder-favrites.active svg path {
    fill: #FCAF17;
    stroke-width: 0;
    stroke: transparent;
}

.folders__item .folder-name {
    display: flex;
    flex-direction: column;
    position: relative;

}

.folders__item .folder-name .folder-img-wrap {
    position: relative;
}

.folders__item .folder-name .folder-img-wrap .count-files-folder {
    position: absolute;
    left: 27px;
    top: 25px;
    background: #016288;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
    padding: 4px 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.folders__item .folder-name p {
    margin-bottom: 0;
    margin-top: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.folders__item .folder-name p span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #9EA8B6;
    margin-top: 4px;
}

/* folder component over */

.btns-groups-grids {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chat-inputs-wrap {
    width: 100%;
    display: inline-block;
    position: relative;
    border-top: 1px solid #E5E8EF;
    padding: 19px 32px 18px 28px;
    background: #FFF;
}

.btn-dropdown {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: 18px;
    color: #FFF;
    padding: 0;
    border: 0;
    background: #016288;
    transform: rotate(0);
    transition: 0.3s linear;
}

.btn-dropdown.active {
    transform: rotate(45deg);
}

.chat-upload-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uplode-drodown {
    position: relative;
}

.upload-dropdown-menu {
    position: absolute;
    bottom: 35px;
    left: 0;
    padding-left: 0;
    margin-bottom: 0;
    display: none;
    z-index: 5;
    transition: 0.3s linear;
}

.upload-dropdown-menu.show {
    display: inline-block;
}

.upload-dropdown-menu li {
    list-style: none;
    margin-bottom: 8px;
    display: block;
}

.upload-dropdown-menu li input {
    display: none;
}

.upload-dropdown-menu li label {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #016288;
    border: 0;
    padding: 0;
}

.input-chat-module {
    width: 46%;
    display: inline-block;
    position: relative;
    margin: 0 28px;
}

.chat-input-message {
    border: 0;
    background: #FFF;
    padding: 10px 0px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    color: rgba(112, 124, 151, 1);
}

.chat-input-message::placeholder {
    color: rgba(112, 124, 151, 0.5);
}

.emoji-btn {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.chat-submit-btn {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 0;
    background: #016288;
    border: 0;
    color: #FFF;
}

.chats-div {
    width: 100%;
    background: #FFF;
    height: 353px;
    overflow: hidden;
    overflow-y: auto;
}

.chat-text-wrap {
    width: 100%;
    padding: 35px 15px 35px 20px;
}

.chat-block-wrap {
    display: block;
}

.chat-left-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.img-block {
    width: 32px;
    height: 32px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    margin-right: 8px;
}

.img-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-chat-wra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.message-chat-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.message-chat-wrap .message-text {
    display: inline-block;
    position: relative;
    background: #016288;
    border-radius: 12px 12px 12px 0;
    color: #FFF;
    padding: 6px 12px;
    margin-right: 12px;
}

.message-chat-wrap .message-text p {
    margin-bottom: 0;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}

.message-chat-wrap .chat-drop {
    display: none;
}

.text-chat-wrap:hover .message-chat-wrap .chat-drop,
.text-chat-wrap:hover .message-chat-wrap .chat-drop {
    display: inline-block;
}

.message-chat-wrap .chat-drop.show {
    display: inline-block;
}

.date-text {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    margin-bottom: 0;
    margin-top: 4px;
    text-transform: uppercase;
    color: rgba(112, 124, 151, 0.7);
}

.pinbar-offcanvas {
    width: 100%;
    max-width: 354px;
}

.pinbar-header-wrap {}

.pinbar-accordion-item {
    width: 100%;
    /* border-color: #E5E8EF; */
    border-radius: 12px 12px 12px 12px !important;
    margin-bottom: 14px;
    overflow: hidden;
    background-color: transparent;
    border: 1px solid #E5E8EF;
}

.pinbar-accordion-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.pinbar-accordion-item:last-child {
    margin-bottom: 0;
}

.pinbar-accordion-header button {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    background-color: transparent;
    padding: 12px;
}

.pinbar-accordion-header button:focus {
    box-shadow: unset;
    border: 0;
}

.pinbar-accordion-header .accordion-button:not(.collapsed) {
    box-shadow: unset;
    border: 0;
    color: #334D6E;
    font-weight: bold;
    background-color: transparent;
}

.pinbar-accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.pinbar-accordion-item:not(:first-of-type) {
    border-top: 1px solid #E5E8EF;
}

.pinbar-accordion-header button::after {
    background: transparent url('../images/plus-accordion-icon.svg') no-repeat center center;
}

.pinbar-accordion-header button:not(.collapsed):after {
    background: transparent url('../images/mins-accordion-icon.svg') no-repeat center center;
    transform: unset !important;
}

.pinbar-accordion-body {
    padding: 14px 26px 12px 26px;
}

.gauge {
    width: 197px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    height: 97px;
    overflow: hidden;
}

.gauge__fill__bg {
    width: 197px;
    height: 197px;
    border: 18px solid;
    border-color: #F5F6F8 #F5F6F8 #FCAF17 #FCAF17;
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transform: rotate(0deg);
    animation: fillAnimation 1s ease-in;
}

@keyframes fillAnimation {
    0% {
        transform: rotate(-45deg);
    }

    50% {
        transform: rotate(135deg);
    }
}

.gauge__cover {
    width: 75%;
    height: 150%;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);

    /* Text */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 35%;
    box-sizing: border-box;
    font-size: 25px;
    line-height: 33px;
    color: #FCAF17;
    font-weight: bold;
}

.gauge__cover small {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #016288;
}

.file-upload {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.file-upload input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
}

.file-upload .label-file-design {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 2px dashed #C2CFE0;
    border-radius: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
    padding: 33px 15px;
    cursor: pointer;
}

.file-upload input[type="file"]:hover+.label-file-design {
    border-style: solid;
    border-color: #334D6E;
}

.file-upload .label-file-design .upload-icon {
    color: #C2CFE0;
    margin-right: 6px;
}

.legend {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    display: block;
}

.legend-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: calc(100% / 2 - 15px);
    float: left;
    margin-bottom: 10px;
    text-align: left;
    font-weight: normal;
    color: #334D6E;
    text-transform: capitalize;
    font-size: 12px;
}

.legend-item .icon {
    width: 12px;
    height: 12px;
    /* background: #016288; */
    margin-right: 10px;
    border-radius: 15px;
}

.legend-item .label {
    color: #334D6E;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    font-style: normal;
}

.legend-item .label span {
    display: block;
    color: #016288;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
}

.recharts-tooltip {
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    padding: 10px 15px;
    text-transform: capitalize;
    border-radius: 5px;
}

.recharts-tooltip p {
    margin-bottom: 0;
}

.chart-line-view-filters {
    margin: 0 -15px;
}

.analysis-buttons {
    /* position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content; */
    width: fit-content;
    margin: auto;
    background: #F5F6F8;
    border-radius: 14px;
}

.analysis-buttons .analysis-button {
    padding: 4px 7px;
    border: none;
    background-color: transparent;
    position: relative;
    z-index: 1;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    /* display: flex; */
    /* align-items: center; */
    letter-spacing: 0.01em;
    color: #016288;
}

.analysis-buttons>button:first-child {
    border-radius: 2rem 0 0 2rem;
}

.analysis-buttons>button:last-child {
    border-radius: 0 2rem 2rem 0;
}

.analysis-buttons .analysis-button.active {
    border-radius: 14px;
    /* margin-left: -1rem;
    margin-right: -1rem; */
    background-color: #016288;
    color: #fff;
    z-index: 2;
}

.chart-line-wraps {
    text-align: center;
    width: 100%;
    height: 500px;
    margin: 0 -15px;
}

.chart-line-wraps .recharts-surface,
.chart-line-wraps .recharts-responsive-container,
.chart-line-wraps .recharts-responsive-container .recharts-wrapper {
    width: 100% !important;
}

/* upload file or images popup css */
.uploading {
    /* width: 100%; */
    /* min-width: 50vw; */
    /* min-height: 50rem; */
    /* height: calc(100vh - 1.6rem); */
    margin: auto;
    background-color: white;
    margin-top: 0rem;
    padding: 1.6rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    width: 85%;
    margin: 0 auto;
}

@media (max-width: 1440px) {
    .uploading {
        margin-top: 0rem;
    }
}

@media (max-height: 768px) {
    .uploading {
        margin-top: 0rem;
    }
}

.uploading__process {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploading .donut-chart svg {
    transform: rotate(-50deg);
}

.uploading .donut-chart {
    width: 12.8rem;
    height: 12.8rem;
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploading .donut-chart .text, .uploading .donut-chart #task .text--bold, #task .uploading .donut-chart .text--bold, .uploading .donut-chart .waveText {
    transform: translate(0, 8%);
}

.uploading .info {
    align-self: center;
    font-weight: bold;
    margin-top: 0.8rem;
}

.uploading .info div {
    margin-bottom: 0.8rem;
}

.uploading .info div:first-child {
    color: #cccccc;
}

.uploading .info div:nth-child(2) {
    font-size: 2.4rem;
}

.uploading .uploading__footer button {
    border: none;
    background: inherit;
    color: #FCAF17 !important;
    cursor: pointer;
    font-weight: bold;
    margin-left: 1.6rem;
    text-decoration: none;
}

.uploading .uploading__footer button:last-child {
    float: right;
    margin-right: 1.6rem;
}

.uploading__process {
    margin-top: 1.6rem;
    height: 30%;
}

.uploading__files {
    height: 45%;
    overflow: auto;
}

.uploading .uploading__process a {
    color: black;
}

.uploading .uploading__process .donut-chart {
    width: 20%;
}

.uploading .uploading__process .info {
    width: 50%;
}

.uploading .uploading__process .button-list {
    width: 30%;
    align-self: center;
    display: flex;
    justify-content: flex-end;
}

.uploading .uploading__process .button-list button {
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    color: #016288;
}

.uploading .uploading__process .button-list button i {
    line-height: 0;
    margin-right: 5px;
}

.uploading .uploading__process .button-list button:first-child {
    margin-right: 2.4rem;
}

.uploading .uploading__files .file {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    height: 3.2rem;
}

.uploading .uploading__files .progress-bar {
    width: 20%;
    background-color: #F5F6F8;
    padding: 0.3px;
    border-radius: 15px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    height: 1.3rem;
    position: relative;
}

.uploading .uploading__files .file-info {
    font-weight: bold;
    margin-left: 1.6rem;
}

.uploading .uploading__files .file-name, .uploading .uploading__files .file-info, .uploading .uploading__files .progress-bar {
    width: 28%;
    align-self: center;
}

.uploading .uploading__files .file-status {
    width: 16%;
    text-align: right;
    align-self: center;
}

.uploading .uploading__files .file-status button {
    text-align: center !important;
    line-height: 3.2rem;
    margin-right: 0.8rem;
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploading .uploading__files .file-status button:first-child {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    display: inline-block;
}

.uploading .uploading__files .file-status button:nth-child(2) {
    width: 16%;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    display: inline-block;
}

.uploading .uploading__files .file-status .btn-danger {
    font-size: 1.2rem;
}

.uploading .uploading__files .file-name i {
    height: 2.4rem;
    margin-right: 1.6rem;
}

.uploading .progress-bar-fill {
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: #016288;
    border-radius: 11px;
    transition: width 500ms ease-in-out;
}

.uploading .uploading__footer {
    height: 10%;
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;
}

.uploading.small-popup {
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding: 0;
    border-radius: 20px 20px 0 0;
    min-height: 0;
}

.uploading.small-popup .uploading__process {
    margin-top: 0;
    height: 100%;
}

.uploading.small-popup .donut-chart {
    padding-top: 3.2rem;
    margin-bottom: 0;
}

.uploading.small-popup .uploading__process .button-list {
    align-self: center;
    padding-right: 4.8rem;
}

.uploading.small-popup .uploading__process .button-list button {
    width: 12.8rem;
    margin-right: 0;
}

.uploading.small-popup .uploading__process .button-list button:last-child {
    margin-left: 2.4rem;
}

/* upload file or images popup css over */

.skylight-close-button {
    display: none;
}

.context-menu {
    width: 100%;
}

.context-menu .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 0.8rem;
}

.context-menu .option-icon {
    width: 2rem;
    padding-right: 0rem;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    color: #4D5964;
}

.context-menu .option-icon i {
    display: inline-block;
    line-height: 0;
}

.context-menu .text-icons {
    font-size: 15px;
    color: #4D5964;
}

.context-menu .option:hover {
    font-weight: 600;
    color: #016288;
}

.context-menu .option:hover .text-icons,
.context-menu .option:hover .option-icon i {
    color: #016288;
}

.newFolderPopup {
    width: 100%;
    padding: 15px;
}

.newFolderPopup label {
    font-weight: 600;
    font-size: 15px;
    color: #334D6E;
    margin-bottom: 20px;
}

.form-control {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 12px 15px;
    background: #F5F6F8;
    border: 0;
    border-radius: 10px;
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #016288;
}

.form-control:focus {
    outline: 1px solid #016288;
    box-shadow: unset;
    background: #F5F6F8;
}

.groups-of-btns {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.groups-of-btns button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0;
    border-radius: 6px;
    font-size: 15px;
    padding: 10px 15px;
    margin-right: 10px;
    letter-spacing: 0.01em;
    color: #4D5964;
}

.groups-of-btns button:last-child {
    margin-right: 0;
    background: #016288;
    color: #FFF;
}

.groups-of-btns button:focus {
    outline: 0;
    box-shadow: unset;
}

.btn-link {
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    color: #016288;
    text-decoration: none;
}

.btn-link:hover {
    color: #FCAF17;
}

.btn-link:focus {
    outline: 0;
    box-shadow: unset;
}

.uploadBtn-menu {
    /* padding: 15px 0 !important; */
    border: 0;
    box-shadow: 0 5px 15px 4px rgba(0, 0, 0, .2);
}

.uploadBtn {
    position: relative;
    font-size: 15px;
    font-weight: normal;
    padding: 15px 15px;
    letter-spacing: 0.5px;
    color: #25313C;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 0;
    overflow: hidden;
}

.uploadBtn:hover {
    font-weight: 500;
    color: #016288;
}

.uploadBtn input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
}

.uploadBtn .icon-upload {
    margin-right: 8px;
    font-size: 20px;
    width: 20px;
}

.uploadBtn:nth-child(2) .icon-upload {
    font-size: 18px;
}

.uploadBtn:focus,
.uploadBtn:active {
    background: transparent;
}

/* checkin modal */
.checkinout {
    padding: 20px 15px;
}

.check-success--title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.check-success--title span {
    font-size: 1.5rem;
    font-weight: 700;
    color: #25313C;
}

.check-success--title i {
    font-size: 1.5rem;
    line-height: 0;
    cursor: pointer;
}

.check-upload {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 35px 0;
}

.round-upload {
    width: 80px;
    height: 80px;
    background: rgba(251, 175, 26, 0.16);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.round-upload input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.round-upload i {
    font-size: 38px;
    color: #FCAF17;
    line-height: 0;
}

.check-upload p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
}

.check-success--sub-title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    color: #25313C;
}

.check-success--sub-title span span {
    color: #016288;
    cursor: pointer;
    font-weight: 500;
}

.check-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.check-button button {
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
    color: #25313C;
    background: transparent;
    border: 0;
    border-radius: 6px;
    margin-left: 15px;
}

.check-button button:last-child {
    margin-left: 0;
    background: #016288;
    color: #FFF;
}

.check-success--sub-title {
    margin: 0.5rem 0;
}

.btn-primary.link {
    padding: 10px 25px;
    font-size: 1rem;
    margin: 15px 0;
}

.check-success--title span .icons-link {
    line-height: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FCAF17;
    border-radius: 50%;
    font-size: 18px;
    margin-right: 8px;
    color: #FFF;
}

.check-success--title .link-title-modal {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #101625;
}

.check-success-buttons2 {
    margin-top: 2rem;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.check-success-buttons2 button {
    background-color: #016288;
    border-radius: 30px;
    color: #fff;
    border: 1px solid #016288;
    cursor: pointer;
    padding: 8px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.check-success-buttons2 button i {
    line-height: 0;
    margin-left: 5px;
    font-size: 18px;
}

.check-success-buttons2 button:last-child {
    margin-left: 25px;
    background: transparent;
    color: #016288;
}

.main-file-modal-wrap {
    width: 100%;
    margin: 0 -15px;
}

.file-small-wrap {
    padding: 15px 20px;
    background: #FFF;
    border: 1px solid #F5F6F8;
    box-sizing: border-box;
    box-shadow: 2px 13px 28px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 250px;
}

.file-small-wrap .file-small-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.file-small-wrap .file-small-content .file-icon-div {
    width: 35px;
    height: 35px;
    display: inline-block;
    margin-right: 17px;
}

.file-small-wrap .file-small-content .file-icon-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.file-small-wrap .file-small-content p {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0px;
}

.file-small-wrap .file-small-content p span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #9EA8B6;
    margin-top: 4px;
}

.file-small-wrap .link-delete-icon {
    font-size: 18px;
    margin-left: 25px;
    color: #e34306;
    cursor: pointer;
    line-height: 0;
}

/* checkin modal over */

.link-file-minimize {
    width: 100%;
    max-width: 94.1%;
    padding: 1rem 1.5rem;
    border-radius: 8px 8px 0 0;
    background: #F5F6F8;
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: space-between;
    z-index: 99;
    margin: 0 auto;
    box-shadow: 0px -6px 18px rgb(0 0 0 / 3%);
    /* left: 50%; */
    /* transform: translate(-3.1%, 0); */
}

.main-link-minimize {
    display: flex;
}

.main-link-minimize .icon-link {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FCAF17;
    border-radius: 50%;
    margin-right: 0.8rem;
    line-height: 0;
    font-size: 30px;
    color: #FFF;
}

.main-link-minimize div p {
    font-size: 18px;
    margin-bottom: 4px;
    font-weight: bold;
    line-height: 28px;
    color: #101625;
}

.main-link-minimize div p:last-child {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.btns-files-minimize {
    display: flex;
    align-items: center;
}

.btns-files-minimize button {
    background: #016288;
    border: 0;
    padding: 11px 20px;
    font-size: 15px;
    font-weight: 600;
    color: #FFF;
    line-height: 18px;
    margin-right: 20px;
    border-radius: 8px;
}

.btns-files-minimize .extend-ic {
    width: 35px;
    height: 35;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    background: transparent;
    border: 0;
    font-size: 20px;
    line-height: 0;
    cursor: pointer;
}

.newFolderPopup .label {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #25313C;
}

.btns-groups {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
}

.btns-groups button {
    background: #016288;
    border: 0;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: 500;
    color: #FFF;
    line-height: 20px;
    border-radius: 5px;
}

.btns-groups button.btn-cancel {
    background: transparent;
    color: #016288;
}

/* move popup */
.movePopup {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 3.2rem 4.8rem;
}

@media (max-height: 768px) {
    .movePopup {
        padding: 1rem 1.2rem;
    }
}

.movePopup--title {
    font-size: 2rem;
    font-weight: bold;
}

.movePopup div {
    /* margin-bottom: 1rem; */
}

.movePopup div:last-child {
    align-self: flex-end;
}

.movePopup button {
    background-color: inherit;
    padding: 8px 20px;
    font-size: 13px;
    line-height: 20px;
    border-radius: 30px;
    color: #252429;
    border-width: 0px;
}

.movePopup button:last-child {
    background-color: #016288;
    padding: 8px 20px;
    font-size: 13px;
    line-height: 20px;
    border-radius: 30px;
    color: white;
    border-width: 0px;
}

.movePopup .move-folder-list--head {
    border-bottom: 1px solid #cccccc;
    height: 3rem;
    margin-bottom: 1.5rem !important;
}

.move-folder-list--head .icon-arrow-left {
    font-size: 25px;
    line-height: 0;
}

.movePopup .move-folder-list--body {
    /* margin-left: 2rem; */
}

.movePopup .move-folder-list--body.is-active {
    background-color: #cccccc;
}

.movePopup .move-folder-list--body .icon-folder,
.move-folder-list--head .icon-folder {
    line-height: 0;
    color: #FCAF17;
    font-size: 25px;
}

.movePopup .move-folder-list--body span {
    flex: 2;
}

.movePopup .move-folder-list--body span i {
    float: right;
}

.movePopup .move-folder-list--body span .icon-arrow-right {
    font-size: 25px;
    line-height: 0;
}

.movePopup .move-folder-list--head, .movePopup .move-folder-list--body {
    display: flex;
    align-items: center;
}

/* move popup over */

/* file properti */
.file-properties__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.file-properties__title span {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #016288;
}

.file-properties__title span .icon-info {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FCAF17;
    border-radius: 50%;
    margin-right: 8px;
    color: #FFF;
    font-size: 20px;
}

.file-properties__title .close-modal-icon {
    font-size: 20px;
    line-height: 0;
    color: #016288;
    cursor: pointer;
}

.file-properties__container {
    display: flex;
}

.file-properties__container table td {
    padding-bottom: 18px;
    vertical-align: top;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
}

.icon-edit-2 {
    line-height: 0;
    font-size: 15px;
}

.file-properties__container table td:first-child {
    width: 40%;
    color: #016288;
}

.file-properties__container table td:nth-child(2) {
    width: 60%;
    color: #000000;
}

.file-properties__container table tr.descrip-td td {
    padding-bottom: 0;
    padding-top: 21px;
}

.file-properties__container table tr.descrip-td.null td {
    margin-top: 0;
}

.file-properties__container table td span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.file-properties__container .description textarea {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #9EA8B6;
    padding: 12px 16px;
}

.file-properties__container .description span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    padding: 17.5px 32px;
    color: #FFFFFF;
    cursor: pointer;
    background: #016288;
    border-radius: 6px;
    display: flex;
    max-width: 159px;
    margin-top: 12px;
    margin-left: auto;
}

.file-properties__container .description span:hover {
    text-decoration: none;
}

.newDemoModal .skylight-dialog {
    transform: scale(0) translate(-50%, -50%) !important;
    padding: 32px !important;
    width: 100% !important;
    max-width: 560px !important;
}

.newDemoModal.show .skylight-dialog {
    transform: scale(1) translate(-50%, -50%) !important;
}

/* file properti over */

/* share modal */
.share-modal {
    padding: 17px;
}

.share-modal .check-success--title {
    margin-bottom: 32px;
}

.share-modal .check-success--title span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #101625;
}

.share-modal .check-success--title span .icon-share {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FCAF17;
    font-size: 18px;
    color: #FFF;
    margin-right: 8px;
}

.share-modal .check-success--title .icon-close-modal {
    font-size: 20px;
    color: #016288;
}

.primary-input {
    width: 100%;
    padding: 9px 16px;
    background: #FFFFFF;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #334D6E;
}

.primary-input::placeholder {
    color: #9EA8B6;
}

.primary-input:focus {
    outline: 1px solid #016288;
    background: #FFF;

}

.share-modal .checkbox-agree {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.share-modal .checkbox-agree input[type="checkbox"] {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #C2CFE0;
    margin-right: 8px;
    display: none;
}

.share-modal .checkbox-agree label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #9EA8B6;
    cursor: pointer;
    position: relative;
    padding-left: 23px;
}

.share-modal .checkbox-agree label::before {
    content: "";
    background: #FFF;
    border: 1px solid #C2CFE0;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 13px;
}

.share-modal .checkbox-agree input[type="checkbox"]:checked~label::before {
    content: "\2713";
    background: #016288;
    border-color: #016288;
    color: #FFF;
    vertical-align: middle;
}

.share-modal .email-share {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
}

.share-modal .email-share button {
    padding: 17.5px 32px;
    background: #016288;
    border: 1px solid #016288;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    border-radius: 6px;
}

.share-modal .email-share button.btn-cancel {
    border-color: #C2CFE0;
    background: #F5F6F8;
    color: #016288;
    margin-right: 16px;
}

/* share modal over */

/* vision popup */

.vision-modal-wrap {
    padding: 17px;
}

.vision-popup-title {
    font-size: 20px;
    color: #25313C;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.vision-popup-title .close-modal {
    font-size: 18px;
    color: #016288;
    line-height: 0;
    cursor: pointer;
}

.table-vision {
    width: 100%;
    border: 0;
}

.table-vision tr th,
.table-vision tr td {
    border: 0;
    padding: 15px;
    border-collapse: collapse;
    font-size: 15px;
    color: #25313C;
}

.table-vision tr th {
    margin-bottom: 15px;
}

.table-vision tr td .interactive {
    line-height: 0;
    font-size: 20px;
    padding: 0 0.5rem;
    cursor: pointer;
}

/* vision popup over */

.back-mainNav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.back-mainNav .icon-arrow-left {
    font-size: 28px;
    color: #25313C;
    line-height: 0;
    margin-right: 15px;
    cursor: pointer;
}

.back-mainNav .path {
    display: flex;
    align-items: center;
}

.back-mainNav .path .icon-folder {
    font-size: 28px;
    color: #FCAF17;
    line-height: 0;
    margin-right: 0px;
    cursor: pointer;
}

.back-mainNav .path span {
    font-size: 16px;
    color: #101625;
    cursor: pointer;
    font-weight: normal;
}

.back-mainNav .path span:hover {
    font-weight: 600;
    color: #016288;
}

.main-body.no-file {
    text-align: center;
}

/* filter modal */

.searchFilterPopup {
    padding: 17px;
}

.filter-title {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 25px;
    color: #101625;
    font-weight: 600;
}

.searchFilterPopup .filter-select,
.searchFilterPopup .filter-select .filter--form {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.searchFilterPopup .filter-select .filter--form-control {
    display: flex;
    margin-bottom: 1.5em;
    align-items: center;
}

.searchFilterPopup .filter-select .filter--form-control label {
    width: 30%;
    display: inline-block;
    margin-bottom: 0;
    font-size: 15px;
    color: #25313C;
}

.searchFilterPopup .filter-select .filter--form-control select {
    width: 50%;
    outline: none;
    display: inline-block;
    position: relative;
    padding: 12px 15px;
    background: #F5F6F8;
    border: 0;
    border-radius: 10px;
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #016288;
}

.searchFilterPopup .filter-select .filter--form-control button {
    padding: 5px 15px;
    background: #F5F6F8;
    border-radius: 5px;
    border: 0;
    text-transform: uppercase;
    position: relative;
}

.searchFilterPopup .filter-select .filter--form-control .menu-location {
    width: 20rem;
    height: 20rem;
    position: absolute;
    top: 100%;
    z-index: 9999;
    background: #fff;
    box-shadow: 0 0 5px 1px #ccc;
    left: 0;
    text-transform: none;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.searchFilterPopup .filter-select .filter--form-control button>.menu-location .list-head {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-left: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem !important;
}

.searchFilterPopup .filter-select .filter--form-control button>.menu-location .list-head .icon-arrow-left {
    font-size: 28px;
    color: #25313C;
    line-height: 0;
    /* margin-right: 10px; */
    cursor: pointer;
}

.searchFilterPopup .filter-select .filter--form-control button>.menu-location .list-head .icon-folder {
    font-size: 20px;
    line-height: 0;
    color: #FCAF17;
}

.searchFilterPopup .filter-select .filter--form-control button>.menu-location .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    -webkit-flex-grow: 2;
    flex-grow: 2;
}

.searchFilterPopup .filter-select .filter--form-control button>div .list .list-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 0.5rem;
    padding: 5px 1rem 5px 1.5rem;
}

.searchFilterPopup .filter-select .filter--form-control button>.menu-location .list .list-item .icon-folder {
    font-size: 25px;
    color: #FCAF17;
    line-height: 0;
    margin-right: 0px;
    cursor: pointer;
}

.searchFilterPopup .filter-select .filter--form-control button>.menu-location .list .list-item:first-child {
    margin-top: 0;
}

.searchFilterPopup .filter-select .filter--form-control button span {
    padding: 0.3em 0em 0.3em 1em;
}

.searchFilterPopup .filter-select .filter--form-control button>div .list .list-item span {
    -webkit-flex: 2 1;
    flex: 2 1;
}

.searchFilterPopup .filter-select .filter--form-control button>div .list .list-item span .icon-arrow-right {
    line-height: 0;
    font-size: 20px;
}

.searchFilterPopup .filter-select .filter--form-control .date-range {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.searchFilterPopup .filter-select .filter--form-control input[type=date] {
    width: 48%;
    display: inline-block;
    position: relative;
    padding: 12px 10px 12px 15px;
    background: #F5F6F8;
    border: 0;
    border-radius: 10px;
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #016288;
}

.searchFilterPopup .filter-select .filter--button-group {
    text-align: right;
    margin-top: 25px;
}

.searchFilterPopup .filter-select .filter--button-group button {
    background-color: inherit;
    padding: 8px 25px;
    border-radius: 30px;
    color: #016288;
    border-width: 0;
    margin-right: 1em;
}

.searchFilterPopup .filter-select .filter--button-group button:last-child {
    background-color: #016288;
    color: #fff;
}

/* filter modal over */

/* table  */
.table-search {
    width: 100%;
    border-spacing: 0 15px;
    border-collapse: separate;
    margin-bottom: 0;
    vertical-align: middle;
}

.table-search thead {
    background: transparent !important;
}

.table-search tr:hover {
    background: #FBFBFB;
}

.table-search thead tr:hover {
    background: transparent;
}

.table-search thead tr th {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #9EA8B6;
    padding: 0 20px 8px;
    border-bottom: 1px solid #C2CFE0;
}

.table-search tbody tr td {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
    text-align: left;
    padding: 16px 20px;
    border: 1px solid #F5F6F8;
    border-left: 0;
    border-right: 0;
}

.table-search tbody tr td:first-child {
    border-left: 1px solid #F5F6F8;
    border-radius: 8px 0 0 8px;
}

.table-search tbody tr td:last-child {
    border-right: 1px solid #F5F6F8;
    border-radius: 0 8px 8px 0;
}

.table-search tr td:last-child {
    position: relative;
}

.table-search tr td .files-dropdown.table-td-drop {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translate(0, -50%);
}

.table-search tr:hover td,
.table-search tr:hover td:first-child,
.table-search tr:hover td:last-child {
    border-color: #016288;
}

.table-search tbody tr td.icon-td {
    text-align: center;
    width: 25px;
}

.table-search tbody tr td.icon-td img {
    margin-right: 0;
}

.table-search .status-td-div {
    display: flex;
    align-items: center;
}

.table-search .status-td-div button {
    width: 32px;
    height: 32px;
    background: transparent;
    border: 1px solid #C2CFE0;
    /* padding: 8px 8px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: flex-start; */
    border-radius: 20px;
    margin-right: 4px;
    font-size: 12px;
    color: #323232;
}

.table-search .status-td-div button:last-child {
    margin-right: 0;
}

.table-search .status-td-div button span {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-left: 6px;
    display: none;
    transition: display 0.3s linear;
}

/* .table-search .status-td-div button:hover span{
    display: flex;
} */

.table-search .status-td-div .btns-likes svg {
    width: 14px;
    height: 14px;
}

.table-search .status-td-div .btns-likes svg path {
    fill: transparent;
    stroke: #323232;
    /* stroke: #323232; */
}

.table-search .status-td-div .btns-likes.active {
    border-color: #FCAF17;
}

.table-search .status-td-div .btns-likes.active svg path {
    fill: #FCAF17;
    stroke: #FCAF17;
    /* fill: #323232; */
}

.table-search .status-td-div .btn-checkout-check {
    font-size: 14px;
}

.table-search .status-td-div .link-btns {
    font-size: 18px;
}

.table-search .folder-path-wraps {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.table-search .folder-path-wraps .folder-icon {
    line-height: 0;
    margin-right: 6px;
    color: #C2CFE0;
    font-size: 18px;
}

.table-search .tb-users {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.table-search .tb-users .user-picture-wrap {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    border: 1px solid #F5F6F8;
    border-radius: 25px;
    object-fit: fill;
    overflow: hidden;
}

.table-search .tb-users .user-picture-wrap img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.table-search .tb-users .user-picture-wrap .user-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #F5F6F8;
    font-size: 10px;
    color: #016288;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-search .icon-with-text {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.table-search .icon-with-text .icon {
    font-size: 18px;
    color: #C2CFE0;
    margin-right: 6px;
    line-height: 0;
}

/* table over */

/* sort dropdown */
.custom-sort-dropmenu {
    background: #FFFFFF;
    box-shadow: -5px 6px 24px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.custom-sort-dropmenu a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #016288;
    padding: 12px 12px 12px 34px;
    position: relative;
    margin-bottom: 0;
}

.custom-sort-dropmenu a::after {
    content: "";
    background: transparent;
    color: #FFF;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #9EA8B6;
}

.custom-sort-dropmenu a.is_active::after {
    content: "\2713";
    /* display: flex; */
    background: #016288;
    border-color: #016288;
}

.custom-sort-dropmenu a:last-child {
    margin-bottom: 0;
}

/* sort dropdown over */

.modal-deletes .skylight-dialog .text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016288;
    margin-bottom: 24px;
}

.modal-deletes .skylight-dialog .delete--button-group {
    max-width: 300px;
    margin: 40px auto 0;
}

.modal-deletes .skylight-dialog .delete--button-group button {
    background-color: inherit;
    padding: 16.5px 25px;
    border-radius: 6px;
    background: #F5F6F8;
    border: 1px solid #C2CFE0;
    width: 100%;
    max-width: 112px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016288;
}

.modal-deletes .skylight-dialog .delete--button-group button:last-child {
    background-color: #016288;
    color: #fff;
}

.add-member-modal .task-popup {
    padding: 24px;
}

.add-member-modal .task-popup .modal-header-task {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-member-modal .task-popup .modal-header-task .heading--active {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.add-member-modal .task-popup .modal-header-task .btn-modal-close {
    background: transparent;
    border: 0;
    line-height: 0;
    font-size: 18px;
    color: #016288;
}

.add-member-modal .task-popup .modal-body-task {
    padding: 24px 0 0;
}

.add-member-modal .task-popup .modal-body-task .css-d9gzy7-control,
.add-member-modal .task-popup .modal-body-task .css-1nk1i9u-control,
.add-member-modal .task-popup .modal-body-task .css-545eo6-control,
.input-wraps .css-d9gzy7-control,
.input-wraps .css-1nk1i9u-control,
.input-wraps .css-545eo6-control,
.primary-select .css-d9gzy7-control,
.primary-select .css-1nk1i9u-control,
.primary-select .css-545eo6-control {
    background: #FFFFFF;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 4px;
}

.group-from-create .css-d9gzy7-control,
.group-from-create .css-1aygw11-control,
.group-from-create .css-1iamj79-control,
.group-from-create .css-1nk1i9u-control,
.group-from-create .css-12xxnu-control {
    background: #FFFFFF;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 4px;
}

.add-member-modal .task-popup .modal-body-task .css-26l3qy-menu,
.input-wraps .css-26l3qy-menu,
.primary-select .css-26l3qy-menu,
.group-from-create .css-26l3qy-menu {
    padding: 0;
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap,
.input-wraps .list-select-member-wrap,
.group-from-create .list-select-member-wrap,
.primary-select .list-select-member-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap .memeber-image-wrap,
.input-wraps .list-select-member-wrap .memeber-image-wrap,
.group-from-create .list-select-member-wrap .memeber-image-wrap,
.primary-select .list-select-member-wrap .memeber-image-wrap {
    width: 100%;
    max-width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap .memeber-image-wrap img,
.input-wraps .list-select-member-wrap .memeber-image-wrap img,
.group-from-create .list-select-member-wrap .memeber-image-wrap img,
.primary-select .list-select-member-wrap .memeber-image-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap .memeber-image-wrap .m-name,
.input-wraps .list-select-member-wrap .memeber-image-wrap .m-name,
.group-from-create .list-select-member-wrap .memeber-image-wrap .m-name,
.primary-select .list-select-member-wrap .memeber-image-wrap .m-name {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #016288;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap .name-of-mamber-wrap,
.input-wraps .list-select-member-wrap .name-of-mamber-wrap,
.group-from-create .list-select-member-wrap .name-of-mamber-wrap,
.primary-select .list-select-member-wrap .name-of-mamber-wrap {
    width: calc(100% - 44px);
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap .name-of-mamber-wrap p,
.input-wraps .list-select-member-wrap .name-of-mamber-wrap p,
.group-from-create .list-select-member-wrap .name-of-mamber-wrap p,
.primary-select .list-select-member-wrap .name-of-mamber-wrap p {
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap .name-of-mamber-wrap p .m-text,
.input-wraps .list-select-member-wrap .name-of-mamber-wrap p .m-text,
.group-from-create .list-select-member-wrap .name-of-mamber-wrap p .m-text,
.primary-select .list-select-member-wrap .name-of-mamber-wrap p .m-text {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 98%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.add-member-modal .task-popup .modal-body-task .list-select-member-wrap .name-of-mamber-wrap p .m-email,
.input-wraps .list-select-member-wrap .name-of-mamber-wrap p .m-email,
.group-from-create .list-select-member-wrap .name-of-mamber-wrap p .m-email,
.primary-select .list-select-member-wrap .name-of-mamber-wrap p .m-email {
    margin-top: 2px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: block;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.8;
}

.add-member-modal .task-popup .modal-body-task .primary-input {
    width: 100%;
    padding: 9px 16px;
    background: #FFFFFF;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #334D6E;
}

.add-member-modal .task-popup .modal-body-task .primary-input::placeholder {
    opacity: 0.5;
}

.add-member-modal .task-popup .modal-body-task .button,
.button-wraps .button {
    background: #016288;
    border-radius: 6px;
    border: 0;
    padding: 12px 14px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.add-member-modal .task-popup .list-of-selected,
.list-of-selected {
    column-count: 3;
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap,
.list-of-selected .add-member-wrap {
    width: calc((100% / 3) - 10px);
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap .memeber-image-wrap,
.list-of-selected .add-member-wrap .memeber-image-wrap {
    width: 100%;
    max-width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap .memeber-image-wrap img,
.list-of-selected .add-member-wrap .memeber-image-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap .memeber-image-wrap .m-name,
.list-of-selected .add-member-wrap .memeber-image-wrap .m-name {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #016288;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap .member-info,
.list-of-selected .add-member-wrap .member-info {
    width: calc(100% - 63px);
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap .member-info .title--bold,
.list-of-selected .add-member-wrap .member-info .title--bold {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap .member-info .text,
.list-of-selected .add-member-wrap .member-info .text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.65;
}

.add-member-modal .task-popup .list-of-selected .add-member-wrap .btn-remove-member,
.list-of-selected .add-member-wrap .btn-remove-member {
    background: 0;
    border: 0;
    line-height: 0;
    font-size: 15px;
    color: #016288;
}

.add-member-modal .task-popup .modal-footer-task,
.create-project-form.modal-footer-task {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
}

.create-project-form.modal-footer-task {
    justify-content: flex-end;
    margin-top: 40px;
}

.add-member-modal .task-popup .modal-footer-task button,
.create-project-form.modal-footer-task button {
    background-color: inherit;
    padding: 16.5px 25px;
    border-radius: 6px;
    background: #F5F6F8;
    border: 1px solid #C2CFE0;
    width: 100%;
    max-width: 112px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016288;
}

.add-member-modal .task-popup .modal-footer-task button:last-child,
.create-project-form.modal-footer-task button:last-child {
    background: #016288;
    border-color: #016288;
    color: #FFF;
}

.create-project-form.modal-footer-task button:last-child {
    margin-left: 16px;
}

.calendar-top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.calendar-top-bar .left-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.calendar-top-bar .interactive-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(42, 139, 242, 0.03), 0px 5px 25px rgba(42, 139, 242, 0.07);
    margin-right: 10px;
}

.calendar-top-bar .interactive-btn i {
    line-height: 0;
    font-size: 22px;
    color: #707C97;
}

.calendar-top-bar .title-calender {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #252525;
    margin-left: 10px;
}

.calendar-top-bar .calender-page-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #016288;
}

.close-wrap-calender {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.close-wrap-calender button {
    background: transparent;
    border: 0;
    line-height: 0;
    color: #016288;
}

.form-group2 {
    margin-bottom: 16px;
}

.form-label2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #016288;
    opacity: 0.8;
    margin-bottom: 8px;
    position: relative;
}

.form-label2.required::after {
    content: '*';
    position: absolute;
    top: 0;
    right: -8px;
    font-size: 20px;
    font-weight: normal;
    color: red;
}

.both-flex-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.both-flex-group .input-wraps {
    width: calc(100% - 65px);
}

.list-of-fileSelected .add-member-wrap {
    border-color: #C2CFE0;
    width: calc((100% / 2) - 10px) !important;
}

.list-of-fileSelected .add-member-wrap .memeber-image-wrap {
    border-radius: 0 !important;
    /* overflow: inherit !important */
}

.list-of-fileSelected .add-member-wrap .memeber-image-wrap img {
    object-fit: contain !important;
    /* width: inherit !important;
    height: inherit !important; */
}

.list-of-fileSelected .add-member-wrap .member-info.file-name .text--small {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.choose-avatar .skylight-dialog {
    background: #FFFFFF;
    border-radius: 12px !important;
    padding: 40px !important;
}

.choose-avatar .skylight-dialog label {
    line-height: inherit !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.choose-avatar .skylight-dialog label .upload-image-placeholder p {
    margin: 32px 0 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E
}

.choose-avatar .skylight-dialog label .upload-image-placeholder p b {
    color: #FCAF17;
}

.choose-avatar .skylight-dialog label .upload-image-placeholder p span {
    display: block;
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.65;
}

.choose-avatar .button-group,
.modal-buttons-wraps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.choose-avatar .button-group button,
.modal-buttons-wraps button {
    background-color: inherit;
    padding: 16.5px 25px;
    border-radius: 6px;
    background: #F5F6F8;
    border: 1px solid #C2CFE0;
    width: 100%;
    max-width: 112px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016288;
}

.choose-avatar .button-group button:last-child,
.modal-buttons-wraps button:last-child {
    background: #016288;
    border-color: #016288;
    color: #FFF;
    margin-left: 24px;
}

.charts-pie {
    width: 80%;
    overflow: hidden;
    margin: 0 auto;
    height: 250px;
}

.pie-chart {
    margin-top: 25px;
}

.c-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin: 15px 0;
}

.c-pagination li a {
    font-size: 14px;
    font-weight: normal;
    color: #016288 !important;
    padding: 6px 10px;
    border-radius: 15px;
    opacity: 0.5;
}

.c-pagination li.active {
    background: transparent !important;
}

.c-pagination li.active a {
    background: #016288;
    color: #FFF !important;
    opacity: 1;
}

.c-pagination li.previous a,
.c-pagination li.next a {
    padding: 5px 10px;
    text-decoration: none;
    text-transform: capitalize;
    border: 1px solid transparent;
    border-radius: 4px;
}

.c-pagination li.previous a {
    margin-right: 10px;
}

.c-pagination li.next a {
    margin-left: 10px;
}

.c-pagination li.disabled a {
    opacity: 0.5;
    cursor: no-drop;
}

/* overlay-wrap */
.overlay-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 50%);
    z-index: 9;
    display: none;
}

.overlay-wrap.is_active {
    display: inline-block;
}

/* overlay-wrap over */

/* mobile header */
.mobile-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    background: #F5F6F8;
    box-shadow: 2px 13px 28px rgb(0 0 0 / 4%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.mobile-header .logo-wrap {
    height: 35px;
    padding: 0;
}

.mobile-header .logo-wrap img {
    /* width: 100%; */
    height: 100%;
}

.mobile-header .btn-mobile-view-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mobile-header .btn-mobile-view-group .btns-mob {
    padding: 2px;
    background: #FFF;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #25313C;
    border: 0;
    box-shadow: 2px 8px 13px rgba(245, 246, 248, 0.8);
    margin-right: 10px;
}

.mobile-header .btn-mobile-view-group .btns-mob:last-child {
    margin-right: 0;
}

.mobile-header .btn-mobile-view-group .btns-mob:hover {
    background: #FCAF17;
    color: #FFF;
}

.mobile-header .btn-mobile-view-group .dropdown-wraps {
    margin-right: 10px;
}

.mobile-header .btn-mobile-view-group button.btn-uploads {
    width: inherit;
    height: inherit;
    border-radius: 5px;
    font-size: 15px;
    padding: 10px 10px !important;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
}

/* mobile header over */

.access-tabs {
    width: 100%;
    /* padding: 0 24px; */
}

.btn-acccess-tabs {
    width: 100%;
    background: transparent !important;
    border: 1px solid #9EA8B6 !important;
    padding: 10px 35px 10px 15px !important;
    font-size: 18px !important;
    color: #016288 !important;
    font-weight: bold !important;
    text-align: left !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.btn-acccess-tabs:hover,
.btn-acccess-tabs:focus {
    background: transparent !important;
    border: 1px solid #9EA8B6 !important;
    color: #016288 !important;
}

.btn-acccess-tabs::after {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%) rotateX(0);
}

.btn-acccess-tabs .notify-icons {
    background: #FCAF17;
    border-radius: 2px;
    font-size: 12px;
    padding: 2px 5px;
}

.access-tabs .uploadBtn-menu {
    width: 100%;
}

.access-tabs .uploadBtn-menu .tab-nav-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 35px 10px 15px;
    margin-bottom: 0;
    color: #334D6E;
}

.access-tabs .uploadBtn-menu .tab-nav-btn span {
    background: #FCAF17;
    border-radius: 2px;
    font-size: 12px;
    padding: 2px 5px;
}

.access-tabs .uploadBtn-menu .tab-nav-btn:hover {
    background: #C2CFE0;
}

.access-tabs .uploadBtn-menu .tab-nav-btn.is_active {
    background: #FCAF17;
    color: #FFF;
}

.access-tabs .uploadBtn-menu .tab-nav-btn.is_active span {
    background: #FFF;
    color: #016288;
}

.access-tabs.show .btn-acccess-tabs::after {
    transform: translate(0, -50%) rotateX(-180deg);
}
.border-danger__control{
    border-color: #dc3545!important;
}
@media screen and (max-width:767px) {
    .chat-inputs-wrap{
        padding: 19px 15px 18px 15px;
    }
}

@media screen and (max-width:320px) {
    .page-top-header.mw-75 {
        max-width: 100%;
    }
}