.details-files-section {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    background: #F5F6F8;
}

.file-details-nav {
    background: #FFF;
    padding: 36px 36px 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-title-wrp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.file-title-wrp .icon-arrow-left {
    font-size: 25px;
    line-height: 0;
    color: #C2CFE0;
    cursor: pointer;
}

.file-title-wrp .title-file {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #016288;
    margin-left: 24px;
    text-transform: uppercase;
}

.file-title-wrp .title-file i {
    line-height: 0;
    margin-right: 8px;
}

.file-title-wrp .title-file i img {
    width: 25px;
}

.button-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button-list button {
    background: #F5F6F8;
    border-radius: 16px;
    border: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    position: relative;
}

.button-list button:last-child {
    margin-right: 0;
}

.button-list button i {
    line-height: 0;
    color: #323232;
    font-size: 20px;
}

.button-list button i.icon-download-circle,
.button-list button i.icon-share {
    font-size: 16px;
}

.button-list button i.icon-info {
    font-size: 22px;
}

.button-list button .link-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background: #FCAF18;
    padding: 2px 4px;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.button-list button:hover {
    background: #016288;
}

.button-list button:hover i {
    color: #FFF;
}

.file-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 2;
}

.viewer-file-main,
.commet-bar-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 102px);
    overflow: hidden;
    overflow-y: auto;
}

.viewer-file {
    width: 100%;
    display: inline-block;
    position: relative;
}

.viewer-file, .pg-viewer-wrapper {
    height: calc(100vh - 140px);
    padding: 20px 50px 0;
}

.viewer-file .pg-viewer-wrapper iframe {
    width: 100%;
    /* height: calc(100vh - 105px); */
}

.ril__closeButton {
    display: none;
}

.commet-bar-wrap {
    max-width: 354px;
    background: #FFF;
}

.commet-bar-wrap .comments-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F5F6F8;
    padding: 17px 20px;
}

.commet-bar-wrap .comments-title p {
    margin-bottom: 0;
}

.commet-bar-wrap .comments-title .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.commet-bar-wrap .comments-title .small-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.comment-container {
    width: 100%;
    padding: 25px 20px;
    list-style: none;
    margin-bottom: 0;
}
.comment-container li{
    margin-bottom: 25px;
    border-bottom: 1px solid #F5F6F8;
    padding-bottom: 20px;
}

.comment-container li:last-child{
    margin-bottom: 0;
}

.comment-container .files-comment {
    display: flex;
    align-items: flex-start;
    
}

.form-commit-wrap {
    padding: 25px 20px;
}

.form-commit-wrap textarea {
    margin-bottom: 25px;
}

.form-commit-wrap button.btn-primary {
    padding: 8px 25px;
    border-radius: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.display-commite-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}

.display-commite-wrap .top-commitd-section {
    width: 100%;
    padding-right: 35px;
    margin-bottom: 10px;
    position: relative;
}

.display-commite-wrap .top-commitd-section .comment-name {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.display-commite-wrap .top-commitd-section .comment-time {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #9EA8B6;
    margin-top: 5px;
}

.display-commite-wrap .top-commitd-section .group-btns-save{
    position: absolute;
    right: 0;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.display-commite-wrap .top-commitd-section .group-btns-save .comment-handle{
    font-size: 15px;
    line-height: 0;
    margin-left: 8px;
    color: #016288;
    cursor: pointer;
}
.display-commite-wrap .top-commitd-section .group-btns-save .comment-handle.save{
    font-size: 18px;
}
.display-commite-wrap .top-commitd-section .group-btns-save .comment-handle.delete{
    color: #ed5b06;
}
.display-commite-wrap .top-commitd-section .group-btns-save .comment-handle:first-child{
    margin-left: 0;
}

.display-commite-wrap .top-commitd-section .comment-content .input-div{
    background: #F5F6F8 !important;
}